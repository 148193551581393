import React from 'react'

const UserPreferences = () => {
    return (
        <div>
            {/* <div id="preloader">
                <div class="spinner-grow text-primary" role="status"><span class="visually-hidden">Loading...</span></div>
            </div>

            <div class="internet-connection-status" id="internetStatus"></div> */}
            <div class="header-area" id="headerArea">
                <div class="container">

                    <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">

                        <div class="logo-wrapper"><a href="#"><img src="img/profile/user-3.jpg" alt="" /></a></div>
                        <div class="logo-wrapper" style={{ marginLeft: "70%" }}><a href="#"><img src="img/coins.png" alt="" /></a></div>

                        <div class="navbar--toggler" id="affanNavbarToggler" data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas"><span class="d-block"></span><span class="d-block"></span><span class="d-block"></span></div>
                    </div>

                </div>
            </div>
            <div class="page-content-wrapper">
                <div class="container direction-rtl p-0" style={{ border: "1px solid #d6d6d6" }}>
                    <div class="container pt-3 px-0">
                        <div class="container p-1">
                            <div class="card">
                                <div class="card-body p-2 mt-4">
                                    <h4 class="text-center">User Preferences</h4>
                                    <h6 class="text-center">I want to be notified on</h6>
                                    <div class="row mt-5">
                                        <div class="col-12">
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>IntradayOption writing trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Positional option writing trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Intraday Index trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Intraday Stock trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Positional Index trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Positional Stock trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>Cash Portfolio trades</b></label>
                                            </div>
                                            <div class="form-check pb-4">
                                                <input class="form-check-input" type="radio" name="exampleRadio" id="primaryRadio" style={{ border: "1px solid grey" }} />
                                                <label class="form-check-label" for="primaryRadio"><b>All</b></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <a class="btn text-white d-block mt-3" href="#" style={{ backgroundColor: "#0d6efd", borderRadius: "20px" }}>Update</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pb-5"></div>

                </div>
            </div>
        </div>
    )
}

export default UserPreferences

import React from 'react'
import "../App.css"
import Footer from '../Footer'
const ChatSupport = () => {
    return (
        <div>
            {/* <div id="preloader">
                <div class="spinner-grow text-primary" role="status"><span class="visually-hidden">Loading...</span></div>
            </div>
            <div class="internet-connection-status" id="internetStatus"></div> */}
            <div class="header-area" id="headerArea">
                <div class="container">
                    <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">

                        <div class="back-button"><a href="elements.html">
                            <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                            </svg></a></div>
                        <div class="logo-wrapper mt-3"><p class="text-black ps-3 pe-3 pt-1 pb-1">Support <i class="bi bi-chat" style={{ fontSize: "16px", paddingLeft: "3px" }}></i></p></div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="chat-content-wrap">

                    <div class="single-chat-item">

                        <div class="user-avatar mt-1">
                            <span class="name-first-letter">A</span><img src="img/logo.jpeg" alt="" />
                        </div>

                        <div class="user-message">
                            <div class="message-content">
                                <div class="single-message">
                                    <p>Hello, how can i help you ?</p>
                                </div>
                            </div>

                            <div class="message-time-status">
                                <div class="sent-time">11:39 AM</div>
                            </div>
                        </div>
                    </div>
                    <div class="single-chat-item outgoing">
                        <div class="user-avatar mt-1">
                        </div>
                        <div class="user-message">
                            <div class="message-content">
                                <div class="single-message">
                                    <p>Nifty trade not closed ?</p>
                                </div>
                            </div>
                            <div class="message-time-status">
                                <div class="sent-time">11:46 AM</div>
                                <div class="sent-status seen"><i class="bi bi-check-lg" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="single-chat-item">
                        <div class="user-avatar mt-1">
                        </div>
                        <div class="user-message">
                            <div class="message-content">
                                <div class="single-message">
                                    <p>Once you logout</p>
                                </div>
                            </div>
                            <div class="message-content">
                                <div class="single-message">
                                    <p>and again login to the app</p>
                                </div>
                            </div>
                            <div class="message-time-status">
                                <div class="sent-time">11:46 AM</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-footer">
                <div class="container h-100">
                    <div class="chat-footer-content h-100 d-flex align-items-center">
                        <form action="#">

                            <input class="form-control" type="text" placeholder="Type here..." style={{ borderColor: "#919191", marginRight: "10px" }} />

                            <button class="btn btn-submit" type="submit">
                                <svg class="bi bi-cursor" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"></path>
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>

    )
}

export default ChatSupport

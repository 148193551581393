import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Footer from './Footer'
import "./App.css"
const Header = () => {
    return (

        <>
            <div class="header-area" id="headerArea">
                <div class="container">

                    <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">

                        <div class="logo-wrapper mt-3">
                            <p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1">
                                <Link class="text-decoration-none" to="/">
                                    Intraday</Link></p></div>
                        <div class="logo-wrapper mt-3"><p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1">
                            <Link class="text-decoration-none" to="/positional">
                                Positional </Link></p></div>
                        <div class="logo-wrapper mt-3"><p class="bg-warning text-black ps-3 pe-3 pt-1 pb-1">
                            <Link class="text-decoration-none" to="/cashportfolio">
                                Cashportfolio</Link></p></div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Header

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Home from './Components/Home';
import IntraDay from './Components/IntraDay'
import Footer from './Footer';
import Positional from './Components/Positional';
import CashPortfolio from './Components/CashPortfolio';
import UserPreferences from './Components/UserPreferences';
import ChatSupport from './Components/ChatSupport';
import LiveUpdates from './Components/LiveUpdates';

function App() {
  return (


    <>
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<IntraDay />} />
          <Route path="/positional" element={<Positional />} />
          <Route path="/cashportfolio" element={<CashPortfolio />} />
          <Route path="/preferences" element={<UserPreferences />} />
          <Route path="/chatsupport" element={<ChatSupport />} />
          <Route path="/settings" element={<LiveUpdates />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </>

  );
}

export default App;
